UC.views.AccountNotificationSettings = Backbone.View.extend({
  id: "sms-settings",

  events: {
    "click .resend-verification": "onResetVerificationClick",
    "submit .sms-settings-form": "onSubmit",
    'submit form.carbon_copy_addresses': 'saveCarbonCopies',
    'change #carbon-copy-addresses': 'onCarbonCopiesChanged',
  },

  initialize: function() {
    var self = this;

    this.STATUS_INTEVAL_SECONDS = 4000; // 4 seconds
    this.STATUS_INTERVAL_STOPPER_MINUTES = 5 * 60 * 1000; // 5 minutes

    UC.utils.fetchTranslations(
      { resource: "sms_phone_number" },
      function() {
        this.startVerification();
      }.bind(this)
    );

    this.$form = this.$el.find(".sms-settings-form");
    this.$id = this.$form.data("id");
    this.$phone = this.$el.find(".phone-number");
    this.$unverifiedMessage = this.$el.find(".unverified");
    this.$verifiedMessage = this.$el.find(".verified");
    this.$verificationStepMessage = this.$el.find(
      ".verification-step-message"
    );
    this.$verificationAlertMessage = this.$el.find(
      ".verification-alert-message .message"
    );
    this.$resendVerification = this.$el.find(".resend-verification");
    this.$areas = this.$el.find(".area");
    this.$phoneNumberLabel = this.$el.find(".phone-number-label");
    this.$notificationPreferences = this.$el.find(".notification-preferences");
    this.$smsPhoneNumberButtonText = this.$el.find(
      ".sms-phone-number-button-text"
    );

    this.$resendVerificationIcon = this.$resendVerification.find(
      ".fontello"
    );

    $("#sms-settings").delegate(".checkbox input[type='checkbox']", "change", function(
      event
    ) {
      var attribute,
        matches,
        id = self.$id || self.$form.data("id");

      // Match rails form attributes like sms_phone_number[NewProposals]
      if ((matches = event.target.name.match(/\[(.*)\]/))) {
        attribute = matches[1];
      }

      var data = {
        sms_phone_number: {}
      };

      data.sms_phone_number[attribute] = $(this).is(":checked");

      UC.net
        .put(
          "/api/v1/sms_phone_numbers/" + id,
          data,
          function(response) {
            self.$optedOut = response.opted_out;
            self.$verified = response.verified;
            UC.utils.showValidationErrors([]);
            UC.utils.showNotification(
              response.message,
              UC.constants.results.success
            );
            self.isOptedOut();
          }
        )
        .fail(
          function(xhr) {
            self.onAjaxError(xhr, 'update notification settings');
          }
        );
    });

    $('#carbon-copy-addresses').select2({
      width: '100%',
      placeholder: 'Add email addresses',
      tags: true,
      tokenSeparators: [',', ' '],
      createTag: function (params) {
        if (params.term.length === 0 || !UC.utils.validateEmail(params.term)) {
          return null;
        }

        return {id: params.term, text: params.term}
      }
    });
  },

  onResetVerificationClick: function(event) {
    this.$resendVerificationIcon.removeClass("hide");
    UC.net
      .post(
        "/api/v1/sms_phone_numbers/" + this.$id + "/resend_verification",
        function(response) {
          this.$resendVerificationIcon.addClass("hide");
          UC.utils.showNotification(
            response.message,
            UC.constants.results.success
          );
        }.bind(this)
      )
      .fail(function() {
        this.$resendVerificationIcon.addClass("hide");
      }.bind(this));
    return false;
  },

  startVerification: function() {
    if (this.verifierTimer) {
      clearInterval(this.verifierTimer);
      clearTimeout(this.verifierTimerStopper);
    }
    if (this.$id) {
      this.verifierTimer = setInterval(
        function() {
          this.getSmsPhoneStatus();
        }.bind(this),
        this.STATUS_INTEVAL_SECONDS
      );
      this.verifierTimerStopper = setTimeout(
        function() {
          if (this.verifierTimer) {
            clearInterval(this.verifierTimer);
          }
        }.bind(this),
        this.STATUS_INTERVAL_STOPPER_MINUTES
      );
      this.getSmsPhoneStatus();
    }
  },

  getSmsPhoneStatus: function() {
    UC.net
      .get(
        "/api/v1/sms_phone_numbers/" + this.$id + "/status",
        function(sms_phone_number) {
          this.$optedOut = sms_phone_number.opted_out;
          this.$verified = sms_phone_number.verified;
          this.$unsubscribed = sms_phone_number.unsubscribed;
          this.setState();
          this.isOptedOut();
        }.bind(this)
      )
      .fail(function() {}.bind(this));
  },

  setState: function() {
    if (this.$verified) {
      this.setVerifiedState();
    } else if (this.$unsubscribed) {
      this.setUnsubscribedState();
    } else {
      this.setUnverifiedState();
    }
  },

  setVerifiedState: function() {
    this.$areas.removeClass("disabled");
    this.$resendVerification.addClass("hide");
    this.$unverifiedMessage.addClass("hide");

    this.$phoneNumberLabel.html(
      UC.utils.translate("sms_phone_number.your_phone_number")
    );
    this.$verificationStepMessage.html(
      UC.utils.translate("sms_phone_number.you_have_agreed_to_receive")
    );
    this.$smsPhoneNumberButtonText.html(
      UC.utils.translate("sms_phone_number.update_phone_number")
    );
    this.$phone.parent().addClass('verified-phone');
  },

  setUnverifiedState: function() {
    this.$areas.addClass("disabled");
    this.$resendVerification.removeClass("hide");
    this.$unverifiedMessage.removeClass("hide");

    this.$phoneNumberLabel.html(
      UC.utils.translate("sms_phone_number.add_your_number")
    );
    this.$verificationStepMessage.html(
      UC.utils.translate("sms_phone_number.verify_phone_number")
    );
    this.$verificationAlertMessage.html(
      UC.utils.translate("sms_phone_number.verify_yes_to_text_message")
    );
    this.$phone.parent().removeClass('verified-phone');
  },

  setUnsubscribedState: function() {
    this.setUnverifiedState();
    this.$resendVerification.addClass("hide");
    this.$verificationAlertMessage.html(
      UC.utils.translate("sms_phone_number.verify_start_to_text_message")
    );
  },

  isOptedOut: function() {
    if (!this.$optedOut && this.$verified) {
      this.$notificationPreferences.removeClass("disabled");
    } else {
      this.$notificationPreferences.addClass("disabled");
    }
  },

  onSubmit: function(event) {
    var self = this,
      attribute,
      matches,
      id = this.$id || this.$form.data("id"),
      errors = [],
      data = { sms_phone_number: {} };

    // Match rails form attributes like sms_phone_number[NewProposals]
    if ((matches = this.$phone.attr("name").match(/\[(.*)\]/))) {
      attribute = matches[1];
      data.sms_phone_number[attribute] = this.$phone.val();
    }

    if (!UC.utils.validatePhone(this.$phone.val())) {
      errors.push({
        className: "form-group",
        element: this.$phone,
        text: "Invalid phone number"
      });
    }

    if (errors.length) {
      UC.utils.showValidationErrors(errors);
      return false;
    } else {
      UC.utils.resetValidationErrors(this.$el);
    }

    if (id) {
      UC.net
        .put(
          "/api/v1/sms_phone_numbers/" + id,
          data,
          function(response) {
            this.setUnverifiedState();
            this.startVerification();
            UC.utils.showNotification(
              response.message,
              UC.constants.results.success
            );
          }.bind(this)
        )
        .fail(
          function(xhr) {
            xhr.handled = true;
            this.onAjaxError(xhr, 'update phone number');
          }.bind(this)
        );
    } else {
      UC.net
        .post(
          "/api/v1/sms_phone_numbers",
          data,
          function(response) {
            var sms_phone_number = response.sms_phone_number;
            this.$id = sms_phone_number._id;
            this.$verificationStepMessage.html(
              UC.utils.translate("sms_phone_number.verify_phone_number")
            );
            UC.utils.showNotification(
              response.message,
              UC.constants.results.success
            );
            this.$phone.val(sms_phone_number.PhoneNumber);
            this.setUnverifiedState();
            this.startVerification();
          }.bind(this)
        )
        .fail(
          function(xhr) {
            xhr.handled = true;
            this.onAjaxError(xhr, 'update phone number');
          }.bind(this)
        );
    }

    return false;
  },

  onAjaxError: function(xhr, action) {
    if (!xhr.responseJSON) {
      UC.utils.showNotification('Failed to ' + action, UC.constants.results.failure);
      return;
    }

    if (xhr.responseJSON.message) {
      UC.utils.showNotification(xhr.responseJSON.message, UC.constants.results.failure);
      return;
    }

    var errors = [];
    for (var attribute in xhr.responseJSON.errors) {
      var inputTag = $("#" + attribute);
      if (inputTag) {
        errors.push({
          className: "form-group",
          element: inputTag,
          text: xhr.responseJSON.errors[attribute][0]
        });
      }
    }
    UC.utils.showValidationErrors(errors);
  },

  saveCarbonCopies: function(e) {
    e.preventDefault();

    const formData = $(e.currentTarget).serializeJSON();
    let validEmails = true;
    if (formData.carbon_copy_addresses && formData.carbon_copy_addresses.length) {
      _.each(formData.carbon_copy_addresses, function(email) {
        if (email.toLowerCase() === UC.user.email.toLowerCase()) {
          validEmails = false;
        }
      });
    }

    if (!validEmails) {
      UC.utils.showNotification(
          `Carbon Copy Recipients should not include primary email address ('${UC.user.email}')`,
          UC.constants.results.failure
      );
      return
    }

    const $loader = $('#save-cc-addresses').buttonLoader();
    $loader.showLoader();

    UC.net.post('/account/emails/save_carbon_copies', formData, function() {
      $loader.hideLoader();
      UC.utils.showNotification('Additional email recipients successfully saved.', UC.constants.results.success);
    }).fail(function() {
      $loader.hideLoader();
    });
  },

  onCarbonCopiesChanged: function (e) {
    e.preventDefault();

    $('#save-cc-addresses').removeAttr('disabled');
  }
});
