!function() {
  
  // This check should be in global UC where we define. Now it is everywhere. Until we figure it out...
  var isTouchDevice = ("ontouchstart" in window) || window.DocumentTouch && document instanceof DocumentTouch;

  UC.utils.initRatingDimensions = function(el) {
    var $el = el ? $(el) : $('.rating-static-wrap');

    $el.popover({
      trigger: isTouchDevice ? 'click' : 'hover',
      container: 'body',
      html: true,
      sanitize: false,
      placement: function() { return $(window).width() < 975 ? 'top' : 'right auto'; },
      content: function() {
        return $(this).find('.review-dimensions-popover').html();
      }
    });
  };
}();
