import '../proposals/accept_proposal'
import './attorney_created_reviews_modal'
import Clipboard from 'clipboard' // webpack-only-import

!function() {
  UC.views.AttorneyProfileShow = Backbone.View.extend({

    events: {
      'click #prevRelevantReviewsLink': 'showPreviousRelevantReviews',
      'click #nextRelevantReviewsLink': 'showNextRelevantReviews',
      'click #prev-pub-page' : 'showPreviousPublicationPage',
      'click #next-pub-page' : 'showNextPublicationPage',
      'click .pf-contact-btn': 'openContactAttyModal',
      'click #prevReviewsLink': 'previousReviewPage',
      'click #nextReviewsLink': 'nextReviewPage',
      'click #show-all-reviews-link': 'showAllReviews',
      'click #show-relevant-reviews-link': 'showRelevantReviews',
      'click #btnWorkHistoryPrev': 'prevWorkHistoryPage',
      'click #btnWorkHistoryNext': 'nextWorkHistoryPage',
      'click #bioMoreLink': 'bioMoreLink',
      'click #btnAddNewWorkHistory': 'openEditWorkHistoryModal',
      'click #btnEditBiography': 'openEditBioModal',
      'click #btnAddPastClients': 'openAddPastClientsModal',
      'click #btnEditPastClients': 'openEditPastClientsModal',
      'click #btnEditPracticeAreas': 'openEditPracticeAreasModal',
      'click #btnEditIndustries': 'openEditIndustriesModal',
      'click #btnOpenLanguagesModal': 'openEditLanguagesModal',
      'click #copyReviewPortalLink': 'copyReviewPortalLink',
      'click .contact-non-subscribed': 'trackNonSubscribedAttorneyWebsiteClick',
      'click .btn-edit-main': 'openEditMainModal',
    },

    initialize: function() {
      var acceptJobProposalOptions = {
        acceptProposalButtonSelector: '.atty-profile-accept-proposal-btn',
        jobId: UC.constants.jobId,
        bidId: UC.constants.bidId,
        hideHireHelpModal: UC.constants.hideHireHelpModal
      };
      UC.acceptJobProposal.init(acceptJobProposalOptions);

      this.$publicationPages = this.$('.publication-page-group');

      if(this.$publicationPages.length > 0) {
        this.lastPublicationPageNum = this.$publicationPages.eq(this.$publicationPages.length -1).data('page');
      } else {
        this.lastPublicationPageNum = 0;
      }

      this.contactAttorneyModal = new UC.modals.ContactAttorneyModal({
        el: '#contactAttorneyModal'
      });

      this.setListeners();

      if (this.$('#profile-bid-details').length) {
        UC.utils.recordKissEvent('Attorney Profile Proposal Shown', {jobId: UC.constants.jobId, attorneyId: UC.constants.attorneyId, userId: UC.user.id})
      }

      this.$el.find('.help').tooltip();

      this.$('[data-toggle="tooltip"]').tooltip();

      UC.utils.initRatingDimensions('.review-dimensions');

      this.initPracticeAreaPopovers();

      this.initCoolFixedHeaderSlidingThingy();

      this.$('#average-rating-dimensions').click(function() {
        UC.utils.scrollToElement('#reviews', { offset: 0 });
      });

      if (UC.constants.isOwner) {

        this.$editWorkHistoryModal = new UC.modals.EditWorkHistory({
          updateWorkHistoryPagination: this.updateWorkHistoryPagination
        });

        this.$editBioModal = new UC.modals.EditBio({
          biographyContent: this.$('#biographyContent')
        });

        this.$editMainModal = new UC.modals.EditMainAttorneyProfileDetails();

        this.$addPastClientsModal = new UC.modals.AttorneyProfileAddPastClients();

        this.$editPastClientsModal = new UC.modals.AttorneyProfileEditPastClients();

        this.$openPracticeAreasModal = new UC.modals.EditPracticeAreas({
          initPracticeAreaPopovers: this.initPracticeAreaPopovers.bind(this)
        });

        this.$openEditIndustriesModal = new UC.modals.EditIndustries({
          saveIndustriesCallback: this.saveIndustriesCallback.bind(this)
        });

        this.$openEditLanguagesModal = new UC.modals.EditLanguages({
          saveCallback: this.saveLanguagesCallback.bind(this)
        });

        UC.attorneyCreatedReviewsModal.init('#btnAddReview');

        this.editModals();
      }

      new UC.components.InputWithCopyButton({inputId: 'referral-url'});

      new Clipboard('#copyReviewPortalLink', {
        text: function () {
          return $('#reviewPortalLink').attr('href');
        },
      }).on('success', function(e) {
        const link = $('#copyReviewPortalLink');
        link.fadeOut(100, function() {
          link.text('copied!').fadeIn(100);
        });
      });
    },

    bioMoreLink: function(e) {
      if (e) e.preventDefault();
      e.preventDefault();
      this.$('#biography').addClass('active');
    },

    openEditBioModal: function(e) {
      this.$editBioModal.openBioModal(e);
    },

    openEditMainModal: function(e) {
      this.$editMainModal.openModal(e);
    },

    openEditWorkHistoryModal: function(e) {
      this.$editWorkHistoryModal.openModal(e);
    },

    openAddPastClientsModal: function(e) {
      if (e) e.preventDefault();
      this.$addPastClientsModal.render();
    },

    openEditPastClientsModal: function(e) {
      if (e) e.preventDefault();
      this.$editPastClientsModal.render();
    },

    openEditPracticeAreasModal: function(e) {
      if (e) e.preventDefault();
      this.$openPracticeAreasModal.openModal();
    },

    openEditIndustriesModal: function(e) {
      if (e) e.preventDefault();
      this.$openEditIndustriesModal.openModal();
    },

    openEditLanguagesModal: function(e) {
      if (e) e.preventDefault();
      this.$openEditLanguagesModal.openModal();
    },

    setListeners: function() {
      var $bidDetailContactAttyBtn = $('.bid-detail-contact-atty-btn');
      var $acceptProposalBtn = $('.atty-profile-accept-proposal-btn');

      if ($bidDetailContactAttyBtn.length > 0 && UC.user) {
        $bidDetailContactAttyBtn.on('click', this.openContactAttyModal.bind(this));
      }

      if ($acceptProposalBtn.length > 0 && UC.user) {
        UC.utils.recordKissClickEvent(
          '.atty-profile-accept-proposal-btn',
          'Attorney Profile Proposal - Hire Button Clicked',
          {
            jobId: UC.constants.jobId,
            attorneyId: UC.constants.attorneyId,
            userId: UC.user.id
          }
        );
      }
    },

    renderRelevantReviewPage: function(page) {
      UC.utils.scrollToElement('#reviews', { offset: 41 });

      var uri = '{0}?page={1}&relevant_page={2}&job_id={3}&viewing_relevant=1'.format(UC.constants.reviewsPath, UC.constants.reviewsPagination.current_page, page, UC.job.id)

      var that = this;
      $.get(uri, function(html) {
        UC.constants.relevantReviewsPagination.current_page = page;
        that.$('#reviewsWrapper').replaceWith(html);
        UC.utils.initRatingDimensions('.review-dimensions');
      })
    },

    showPreviousRelevantReviews: function(e) {
      e.preventDefault();
      var page = UC.constants.relevantReviewsPagination.current_page;
      this.renderRelevantReviewPage(page - 1);
    },

    showNextRelevantReviews: function(e) {
      e.preventDefault();
      var page = UC.constants.relevantReviewsPagination.current_page;
      this.renderRelevantReviewPage(page + 1);
    },

    previousReviewPage: function(e) {
      e.preventDefault();
      var page = UC.constants.reviewsPagination.current_page;
      this.renderReviewPage(page - 1);
    },

    nextReviewPage: function(e) {
      e.preventDefault();
      var page = UC.constants.reviewsPagination.current_page;
      this.renderReviewPage(page + 1);
    },

    renderReviewPage: function(page) {
      UC.utils.scrollToElement('#reviews', { offset: 41 });

      var uri = '{0}?page={1}'.format(UC.constants.reviewsPath, page);

      if (UC.constants.relevantReviewsPagination) {
        var currentPage = UC.constants.relevantReviewsPagination.current_page;
        uri += '&relevant_page={0}&job_id={1}'.format(currentPage, UC.job.id);
      }

      var that = this;
      $.get(uri, function(html) {
        UC.constants.reviewsPagination.current_page = page;
        that.$('#reviewsWrapper').replaceWith(html);
        UC.utils.initRatingDimensions('.review-dimensions');
      });
    },

    showAllReviews: function () {
      this.$('#show-relevant-reviews-link').removeClass('active');
      this.$('#show-all-reviews-link').addClass('active');

      this.$('#allReviewsWrapper').removeClass('hidden');
      this.$('#relevantReviewsWrapper').addClass('hidden');
    },

    showRelevantReviews: function () {
      this.$('#show-relevant-reviews-link').addClass('active');
      this.$('#show-all-reviews-link').removeClass('active');

      this.$('#allReviewsWrapper').addClass('hidden');
      this.$('#relevantReviewsWrapper').removeClass('hidden');
    },

    prevWorkHistoryPage: function(e) {
      e.preventDefault();

      UC.utils.scrollToElement('#work-history', { offset: 41 });

      var $current = this.$('#workHistoryContent .tab-pane.active');
      $current.removeClass('active').prev().addClass('active');

      this.updateWorkHistoryPagination();
    },

    nextWorkHistoryPage: function(e) {
      e.preventDefault();

      UC.utils.scrollToElement('#work-history', { offset: 0 });

      var $current = this.$('#workHistoryContent .tab-pane.active');
      $current.removeClass('active').next().addClass('active');

      this.updateWorkHistoryPagination();
    },

    updateWorkHistoryPagination: function() {
      var $tabs = this.$('#workHistoryContent .tab-pane');
      var $current = $tabs.filter('.active');
      var $prev = this.$('#btnWorkHistoryPrev');
      var $next = this.$('#btnWorkHistoryNext');

      if ($current.index() > 0) {
        $prev.removeClass('hide');
      } else {
        $prev.addClass('hide');
      }

      if ($current.index() < $tabs.length - 1) {
        $next.removeClass('hide');
      } else {
        $next.addClass('hide');
      }
    },

    saveIndustriesCallback: function(response) {
      this.$('#industriesContent').html(response.html);
    },

    saveLanguagesCallback: function(response) {
      this.$('#languagesContent').html(response.html);
    },

    initPracticeAreaPopovers: function() {
      this.$('.legal-group').each(function() {
        var $popover_handle = $(this).find('.popover-handle');
        $popover_handle.popover({
          trigger: 'hover',
          container: $(this),
          html: true,
          placement: function() { return $(window).width() < 975 ? 'top' : 'right auto'; },
          content: $(this).find('.legal-group-popover').html()
        });

        $(this).hover(function() {
          $popover_handle.popover('show');
        }, function() {
          $popover_handle.popover('hide');
        });
      });
    },

    initCoolFixedHeaderSlidingThingy: function() {
      var $overview = this.$('#overview');
      if ($overview.length === 0) {
        return; // for inactive profiles
      }
      var $nav = this.$('#sub-nav');
      var $fixed_nav = $nav.clone().addClass('navbar-fixed-top slide-down').appendTo('#content');
      var $doc = $(document);

      $('body').scrollspy({
        target: '.sub-nav',
        offset: 41
      });

      var fixed_start = $overview.offset().top;

      $(window).scroll(function() {
        var scroll_top = $doc.scrollTop();

        if (scroll_top >= fixed_start) {
          $fixed_nav.addClass('active');
        } else {

          if (this.auto_scrolling == true) {
            return;
          }

          $fixed_nav.removeClass('active');
        }
      });

      // smooth scrolling
      this.$('.sub-nav a').click(this.onNavClick);
    },

    onNavClick: function (e) {
      e.preventDefault();

      var $this = $(this);
      var tab = $this.attr('href');
      var target = $($this.attr('href'));

      this.auto_scrolling = true;

      UC.utils.scrollToElement(target, {
        offset: 41,
        callback: function() {
          setTimeout(function() {
            this.auto_scrolling = false;
          }, 100);
        }
      });
      UC.utils.recordKissEvent('Profile - Nav Tab Click', {'page' : tab });
    },

    editModals: function() {
      var locationHash = location.hash.replace('#', '');

      if (locationHash.indexOf('edit') == 0) {
        switch(locationHash){
          case 'edit-bio':
            this.openEditBioModal();
            break;
          case 'edit-main':
            this.openEditMainModal()
            break;
          case 'edit-areas':
            this.openEditPracticeAreasModal();
            break;
          case 'edit-industries':
            this.openEditIndustriesModal();
            break;
        }
      } else if (UC.utils.getParameterByName('act') === 'contact') {
        UC.page.profile.onContactAttorneyClick();
      }
    },

    openContactAttyModal: function(e) {
      e.preventDefault();

      if (UC.user.status === UC.constants.UserStatus.Pending) {
        new UC.modals.ModalPendingUser().render('Please confirm your email address before sending a message');
      } else {
        this.contactAttorneyModal.open();
      }
    },

    showPreviousPublicationPage: function(e) {
      if (e) e.preventDefault();
      var currentPage = this.$publicationPages.filter('.active').data('page');
      var prevPage = currentPage - 1;
      this.$publicationPages.removeClass('active');
      this.$publicationPages.filter('[data-page="' + prevPage + '"]').addClass('active');
      this.updatePubPageBtns(prevPage);
    },

    showNextPublicationPage: function(e) {
      if (e) e.preventDefault();
      var currentPage = this.$publicationPages.filter('.active').data('page');
      var nextPage = currentPage + 1;
      this.$publicationPages.removeClass('active');
      this.$publicationPages.filter('[data-page="' + nextPage + '"]').addClass('active');
      this.updatePubPageBtns(nextPage);
    },

    updatePubPageBtns: function(pageShown) {
      if(pageShown === 1) this.$('#prev-pub-page').addClass('hidden');
      if(pageShown > 1) this.$('#prev-pub-page').removeClass('hidden');
      if(pageShown < this.lastPublicationPageNum) $('#next-pub-page').removeClass('hidden');
      if(pageShown === this.lastPublicationPageNum) $('#next-pub-page').addClass('hidden');
    },

    trackNonSubscribedAttorneyWebsiteClick: function(e) {
      UC.utils.sendNesEvent('redirect_to_personal_website', {attorney_id: UC.constants.attorneyId})
    },

    copyReviewPortalLink: function(e) {
      if (e) e.preventDefault();
    }
  })
}();
